import React, { useState, useRef } from "react";
import { StatTable } from "./StatTable";
import { PlayerToken } from "./PlayerToken";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const CombatTracker = ({ combatants, setCombatants }) => {
  const [turn, setTurn] = useState(0);
  const [rounds, setRounds] = useState(0);
  const hpValueRef = useRef();
  const combatant = combatants[turn];

  return [
    <Row>
      <Col>
        {combatant?.isPlayer ? (
          <PlayerToken player={combatant}></PlayerToken>
        ) : (
          <StatTable monster={combatant}></StatTable>
        )}
      </Col>
    </Row>,
    <Row><div className="pin">
      {!combatant?.isPlayer && (
        <Row>
          <Col>
            <InputGroup>
              <Button
                variant="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  setCombatants((combatants) => {
                    const comName = combatant.name;
                    return combatants
                      .filter((com) => com.name !== comName)
                      .map((com, index) => ({ ...com, place: index + 1 }));
                  });
                }}
              >
                Remove from lineup
              </Button>
            </InputGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          {!combatant?.isPlayer && (
            <InputGroup className="mb-3">
              <InputGroup.Text>Hitpoints</InputGroup.Text>
              <Form.Control
                type="number"
                ref={hpValueRef}
                defaultValue={combatant?.currentHP || 0}
                key={combatant.name}
              />
              <Button
                variant="primary"
                onClick={() => {
                  setCombatants((combatants) =>
                    combatants.with(turn, {
                      ...combatant,
                      currentHP: hpValueRef.current.value,
                    })
                  );
                }}
              >
                Set
              </Button>
            </InputGroup>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            size="lg"
            variant="primary"
            style={{ width: "100%" }}
            onClick={() => {
              setTurn((turn) => (turn + 1) % combatants.length);
              if ((turn + 1) % combatants.length === 0) {
                setRounds((rounds) => rounds + 1);
              }
            }}
          >
            Next: {combatants[(turn + 1) % combatants.length].name}
          </Button>
        </Col>
      </Row>
    </div></Row>,
  ];
};
