export const PlayerToken = ({player}) => {
    return <div style={{
        backgroundImage: `url("${player?.imageUrl || 'https://media-waterdeep.cursecdn.com/attachments/2/656/humanoid.jpg'}")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '80vh',
        display: 'flex'
    }}
    >
        <table>
            <tbody>
                <tr>
                    <th><h2>{player.name}</h2></th>
                    <th><h3>{player.place}</h3></th>
                </tr>
                <tr>
                    <th><h2>Have {player.name} take their turn at this point.</h2></th>
                </tr>
            </tbody>
        </table>
    </div>
}