import monsterDB from "./srd_5e_monsters.json";
import Select from "react-select";
import "./App.css";
import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { css } from "@emotion/css";

import { CombatTracker } from "./CombatTracker";
import { CombatantRow } from "./MonsterRow";
import { PartyList, ManageRoster } from "./CharacterStorage";
import "bootstrap/dist/css/bootstrap.min.css";

import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";

let lastRoll = 0;
const diceRoll = (min, max) => {
  let roll = Math.floor(Math.random() * max + min);
  while (roll === lastRoll) {
    roll = Math.floor(Math.random() * max + min);
  }
  return roll;
};

const d20 = () => diceRoll(1, 20);

const LineupCreation = ({ combatants, setCombatants, setShouldShowLineup }) => {
  const [mode, setMode] = useState(1);

  return [
    <Row>
      <h2>Add combatants</h2>
      <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
        <ToggleButton
          id="tbg-radio-1"
          variant="info"
          value={1}
          onClick={(e) => {
            setMode(1);
            setShouldShowLineup(true);
          }}
        >
          Monsters
        </ToggleButton>
        <ToggleButton
          id="tbg-radio-2"
          variant="info"
          value={2}
          onClick={(e) => {
            setMode(3);
            setShouldShowLineup(true);
          }}
        >
          Characters
        </ToggleButton>
        <ToggleButton
          id="tbg-radio-3"
          variant="info"
          value={3}
          onClick={(e) => {
            setMode(2);
            setShouldShowLineup(false);
          }}
        >
          Party
        </ToggleButton>
      </ToggleButtonGroup>
    </Row>,
    <Row>
      <Col>
        {mode === 1 && (
          <Select
            key={combatants.length}
            placeholder={"Add monster"}
            onChange={(selection) => {
              const monster = monsterDB[selection.value];
              const howManySame = combatants.filter((com) =>
                com.name.startsWith(monster.name)
              ).length;
              setCombatants((combatants) => [
                ...combatants,
                {
                  ...monster,
                  name: monster.name + (howManySame ? ` (${howManySame})` : ""),
                  currentHP: parseInt(monster.HP.split(" ")[0]),
                },
              ]);
            }}
            options={monsterDB.map((monster, index) => ({
              value: index,
              label: monster.name,
            }))}
            classNamePrefix="react-select"
          >
            Add a monster
          </Select>
        )}
        {mode === 2 && <ManageRoster />}
        {mode === 3 && <PartyList setCombatants={setCombatants} />}
      </Col>
    </Row>,
  ];
};

function App() {
  const [buildMode, setBuildMode] = useState(true);
  const [combatants, setCombatants] = useState([]);
  const [shouldShowLineup, setShouldShowLineup] = useState(true);
  return (
    <Container>
      <Row>
        <Col>
          <h1 className={css`
            width: fit-content;
            margin: auto auto;
          `}>Combat Tracker</h1>
        </Col>
      </Row>
      {buildMode && (
        <Row>
          <Col>
            <Button
              size="lg"
              variant="primary"
              style={{ width: "100%" }}
              disabled={!combatants.length}
              onClick={() => {
                const initiativeOrder = combatants
                  .map((com) =>
                    com?.isPlayer
                      ? com
                      : {
                          ...com,
                          initiative:
                            d20() + parseInt(com.DEX_mod.replace(/\D/g, "")),
                        }
                  )
                  .sort((a, b) => b.initiative - a.initiative)
                  .map((com, index) => ({ ...com, place: index + 1 }));
                setCombatants(initiativeOrder);
                setBuildMode(false);
              }}
            >
              Start Combat
            </Button>
          </Col>
        </Row>
      )}
      {buildMode && (
        <LineupCreation
          className="loadout__panel"
          setCombatants={setCombatants}
          combatants={combatants}
          setShouldShowLineup={setShouldShowLineup}
        />
      )}
      {buildMode && shouldShowLineup && (
        <Row>
          <Col>
            <h2>Line up:</h2>
            {combatants.length === 0 && (
              <div>
                <b>Instructions</b>
                <p>Add your monsters, then add your characters.</p>
                <p>
                  When you click "Start Combat" the page will roll monster
                  initiative for you and place all combatants in the correct
                  order.
                </p>
                <p>
                  Mainstay NPCs and party member names can be saved, along with
                  a link to their artwork, in the "Party Info" menu.
                </p>
              </div>
            )}
            <ul style={{ lineHeight: "2.5rem" }}>
              {combatants.map((combatant, index) => (
                <li>
                  <CombatantRow
                    index={index}
                    monster={combatant}
                    setCombatants={setCombatants}
                  />
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      )}
      {!buildMode && (
        <CombatTracker combatants={combatants} setCombatants={setCombatants} />
      )}
    </Container>
  );
}

export default App;
