import { jsx } from "@emotion/react";
import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { css } from "@emotion/css";

const displayStats = ({ monster }) => {
  const { Speed, STR_mod, DEX_mod, CON_mod, WIS_mod, INT_mod, CHA_mod } =
    monster;

  return (
    <table>
      <tr>
        <td colSpan={6}>
          <b>Speed</b>: {Speed}
        </td>
      </tr>
      <tr>
        <td colSpan={6}>
          <strong>Saving Throws</strong> {monster["Saving Throws"] || "None"}
        </td>
      </tr>
      <tr>
        <td colSpan={6}>
          <strong>Skills</strong> {monster["Skills"] || "None"}
        </td>
      </tr>
      <tr>
        <td colSpan={6}>
          <strong>Senses</strong> {monster["Senses"] || "None"}
        </td>
      </tr>
      <tr>
        <td colSpan={6}>
          <strong>Languages</strong> {monster["Languages"] || "None"}
        </td>
      </tr>
      <tr>
        <td colSpan={6}>
          <strong>Damage Immunities</strong>{" "}
          {monster["Damage Immunities"] || "None"}
        </td>
      </tr>
      <tr>
        <td colSpan={6}>
          <strong>Damage Resistances</strong>{" "}
          {monster["Damage Resistances"] || "None"}
        </td>
      </tr>
      <tr>
        <td colSpan={6}>
          <strong>Condition Immunities</strong>{" "}
          {monster["Condition Immunities"] || "None"}
        </td>
      </tr>
      <tr style={{ textAlign: "center" }}>
        <th colSpan={1}>Str</th>
        <th colSpan={1}>Dex</th>
        <th colSpan={1}>Con</th>
        <th colSpan={1}>Int</th>
        <th colSpan={1}>Wis</th>
        <th colSpan={1}>Cha</th>
      </tr>
      <tr style={{ textAlign: "center" }}>
        <td>{STR_mod.replace(/[()]/g, "")}</td>
        <td>{DEX_mod.replace(/[()]/g, "")}</td>
        <td>{CON_mod.replace(/[()]/g, "")}</td>
        <td>{INT_mod.replace(/[()]/g, "")}</td>
        <td>{WIS_mod.replace(/[()]/g, "")}</td>
        <td>{CHA_mod.replace(/[()]/g, "")}</td>
      </tr>
    </table>
  );
};

export const displayTraitsAndActions = ({ monster }) => {
  const specialRow = (dataName) =>
    monster[dataName] && [
      <tr>
        <th colSpan={6}>
          <h2>{dataName}</h2>
        </th>
      </tr>,
      <tr>
        <td
          colSpan={6}
          dangerouslySetInnerHTML={{ __html: monster[dataName] }}
        ></td>
      </tr>,
    ];

  return (
    <table>
      {specialRow("Traits")}
      {specialRow("Actions")}
      {specialRow("Legendary Actions")}
    </table>
  );
};

const displayQuickView = ({ monster }) => {
  const { HP, AC, Speed, currentHP } = monster;
  const Badge = ({
    value,
    label = "❤️",
    fgColor = "black",
    bgColor = "hotpink",
  }) => (
    <div
      className={css`
        height: 4rem;
        width: 4rem;
        background-color: ${bgColor};
        background-image: linear-gradient(0deg, ${bgColor} 0%, #fff 100%);
        color: ${fgColor};
        border-radius: 100px;
        font-weight: bolder;
        border: black solid 1px;
      `}
    >
      <div
        className={css`
          margin: auto auto;
          width: fit-content;
          height: fit-content;
          font-size: large;
        `}
      >
        {label}
      </div>
      <div
        className={css`
          margin: auto auto;
          width: fit-content;
          height: fit-content;
        `}
      >
        {value}
      </div>
    </div>
  );

  const getMovementTypes = (speed) =>
    speed.split(",").map((spd) =>
      spd[0] >= "0" && spd[0] <= "9"
        ? { type: "walk", speed: parseInt(spd.split(" ft.")[0]) } // there's no "type" so this is walking
        : { type: spd.split(" ")[1], speed: parseInt(/\d+/.exec(spd)) }
    );

  const movementIcons = {
    walk: "🥾",
    fly: "🪽",
    swim: "🏊",
    burrow: "🕳️",
    climb: "🪜",
  };

  return (
    <div className={css`
      display: flex;
      height: 60vh;
      flex-direction: column;
      justify-content: space-between;
    `}>
      <div
        className={css`
          display: flex;
          width: fit-content;
          margin: 0 auto;
        `}
      >
        <Badge
          value={`${currentHP ? currentHP : HP.split(" ")[0]}|${HP.split(" ")[0]}`}
          bgColor={"red"}
        ></Badge>
        <Badge value={AC.split(" ")[0]} bgColor={"silver"} label={"🛡️"}></Badge>
      </div>
      <div
        className={css`
          display: flex;
          width: fit-content;
          margin: 0 auto;
        `}
      >
        {getMovementTypes(Speed).map((movement) => (
          <Badge
            value={`${movement.speed} ft`}
            bgColor={
              "hsl(" +
              360 * Math.random() +
              "," +
              (25 + 70 * Math.random()) +
              "%," +
              (85 + 10 * Math.random()) +
              "%)"
            }
            label={movementIcons[movement.type]}
          ></Badge>
        ))}
      </div>
    </div>
  );
};

export const StatTable = ({ monster }) => {
  const { name, place, meta, img_url } = monster;
  return (
    <div
      style={{
        backgroundImage: `url("${img_url}")`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        height: "68vh",
        display: "flex",
        flexDirection: "column",
      }}
      className="monster-container"
    >
      <h2>
        ({place}) {name}
      </h2>
      <i>
        <sup>{meta}</sup>
      </i>
      <Tabs>
        <TabList>
          <Tab>Quick View</Tab>
          <Tab>Stats</Tab>
          <Tab>Traits & Actions</Tab>
        </TabList>
        <TabPanel>{displayQuickView({ monster })}</TabPanel>
        <TabPanel>{displayStats({ monster })}</TabPanel>
        <TabPanel>{displayTraitsAndActions({ monster })}</TabPanel>
      </Tabs>
    </div>
  );
};
