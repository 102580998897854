import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

import { useLocalStorage } from "@uidotdev/usehooks";
import React, { useState, useRef } from "react";

export const ManageRoster = ({ setCombatants }) => {
  const [roster, setRoster] = useLocalStorage("roster", []);
  return (
    <ul>
      <li>
        <CreateRosterMember setRoster={setRoster} key="blank" />
      </li>
      <hr />
      {roster.map((member) => (
        <li>
          <CreateRosterMember
            key={member.name}
            name={member.name}
            imageUrl={member.imageUrl}
            setRoster={setRoster}
          />
        </li>
      ))}
    </ul>
  );
};

const CreateRosterMember = ({
  setRoster,
  name = undefined,
  imageUrl = undefined,
}) => {
  const nameRef = useRef();
  const imageRef = useRef();
  return (
    <InputGroup key="blank" className="mb-3">
      <InputGroup.Text size="lg">Name</InputGroup.Text>
      <Form.Control
        type="text"
        ref={nameRef}
        placeholder="Add a name here"
        defaultValue={name}
        disabled={Boolean(name)}
      />
      <InputGroup.Text size="lg">Image URL</InputGroup.Text>
      <Form.Control
        type="text"
        ref={imageRef}
        placeholder="URL to an image here"
        defaultValue={imageUrl}
        disabled={Boolean(imageUrl)}
      />
      {!name && (
        <Button
          onClick={() => {
            const charName = nameRef.current.value;
            const imageUrl = imageRef.current.value;
            setRoster((members) => [...members, { name: charName, imageUrl }]);
            nameRef.current.value = "";
            imageRef.current.value = "";
          }}
          variant="primary"
        >
          💾
        </Button>
      )}
      {name && (
        <Button
          variant={"danger"}
          onClick={() => {
            setRoster((roster) =>
              roster.filter((member) => member.name !== name)
            );
          }}
        >
          🗑️
        </Button>
      )}
    </InputGroup>
  );
};

export const PartyList = ({ setCombatants }) => {
  const [roster, setRoster] = useLocalStorage("roster", []);
  return (
    <ul>
      <li>
        <AddPartyMemberOrNPC key="blank" setCombatants={setCombatants} />
      </li>
      {roster.map((member) => (
        <li>
          <AddPartyMemberOrNPC
            key={member.name}
            setCombatants={setCombatants}
            presetName={member.name}
            imageUrl={member.imageUrl}
          />
        </li>
      ))}
    </ul>
  );
};

const AddPartyMemberOrNPC = ({
  setCombatants,
  presetName = undefined,
  imageUrl = undefined,
}) => {
  const nameRef = useRef();
  const initiativeRef = useRef();
  return (
    <InputGroup key="blank" className="mb-3">
      <Form.Control
        type="text"
        ref={nameRef}
        placeholder="Name"
        defaultValue={presetName}
        disabled={presetName}
      />
      <InputGroup.Text size="lg">Init.</InputGroup.Text>
      <Form.Control
        type="number"
        htmlSize={3}
        placeholder="0"
        ref={initiativeRef}
      />
      <Button
        onClick={() => {
          const name = nameRef.current.value;
          const initiative = initiativeRef.current.value;
          setCombatants((combatants) => [
            ...combatants,
            { isPlayer: true, name, initiative, imageUrl },
          ]);
          if (!presetName) {
            nameRef.current.value = "";
          }
          initiativeRef.current.value = 0;
        }}
      >
        Add
      </Button>
    </InputGroup>
  );
};
