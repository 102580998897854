import monsterDB from "./srd_5e_monsters.json";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";

export const CombatantRow = ({ monster, index, setCombatants }) => {
  const [isBuilt, setIsBuilt] = useState(true);

  return isBuilt ? (
    <div>
      <a
        style={{
          textDecorationLine: "underline",
          textDecorationStyle: "dashed",
          cursor: 'pointer'
        }}
        onClick={() => {
          setIsBuilt(false);
        }}
      >
        {monster.name}
      </a>
    </div>
  ) : (
    <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
      {monster?.isPlayer ? (
        <PlayerRow
          monster={monster}
          index={index}
          setCombatants={setCombatants}
          setIsBuilt={setIsBuilt}
        />
      ) : (
        <MonsterRow
          monster={monster}
          index={index}
          setCombatants={setCombatants}
          setIsBuilt={setIsBuilt}
        />
      )}
      <Button
        variant="link"
        style={{
          textDecorationLine: "underline",
          textDecorationStyle: "dashed",
        }}
        onClick={() => setIsBuilt(true)}
      >
        Cancel
      </Button>
      <Button
        variant="link"
        style={{
          textDecorationLine: "underline",
          textDecorationStyle: "dashed",
        }}
        onClick={() => {
          setIsBuilt(true);
          setCombatants((combatants) =>
            combatants.filter((v, i) => i !== index)
          );
        }}
      >
        Delete
      </Button>
    </div>
  );
};

const MonsterRow = ({ monster, index, setCombatants, setIsBuilt }) => (
  <Select
    placeholder={"Change monster"}
    onChange={(selection) => {
      setCombatants((combatants) =>
        combatants.with(index, monsterDB[selection.value])
      );
      setIsBuilt(true);
    }}
    onBlur={() => setIsBuilt(true)}
    defaultValue={monster.name}
    options={monsterDB.map((monster, index) => ({
      value: index,
      label: monster.name,
    }))}
    unstyled
    classNamePrefix="react-select"
  />
);

const PlayerRow = ({ monster, index, setCombatants, setIsBuilt }) => {
  const [editMode, setEditMode] = useState(false);
  const [playerName, setPlayerName] = useState("");

  return editMode ? (
    <Form.Control
      size="lg"
      type="text"
      placeholder="Player name"
      onChange={(e) => {
        console.log({ e });
        setPlayerName(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          setCombatants((combatants) =>
            combatants.with(index, { isPlayer: true, name: e.target.value })
          );
          e.target.value = "";
        }
      }}
    />
  ) : (
    <Button
      style={{ textDecorationLine: "underline", textDecorationStyle: "dashed" }}
      onClick={() => setEditMode(true)}
      variant="link"
    >
      Rename
    </Button>
  );
};
